import React from "react"
import st from "./Drawer.module.css"

const Drawer = ({ children, isDrawerOpen }) => {
  return (
    <div className={`${st.drawer} ${isDrawerOpen && st.drawerVisible}`}>
      {children}
    </div>
  )
}

export default Drawer
