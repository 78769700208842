import React, { useState } from "react"
import createAndClickLink from "../../utils/createLinkAndClick"
import Drawer from "../Drawer/Drawer"
import TutorialiOS from "../TutorialiOS/TutorialiOS"
import st from "./VideoLanding.module.css"

import video from "../../assets/video/videolanding-2641.mp4"
import btn from "../../assets/images/Landings/videolanding-2641/btn-appstore.png"

const VideoLanding = ({ appURL }) => {
  const [isDrawerOpen, setDrawerIsOpen] = useState(false)

  const toggleDrawer = () => {
    setDrawerIsOpen(!isDrawerOpen)
  }

  const onClickDownload = () => {
    createAndClickLink(appURL)
    toggleDrawer()
  }

  return (
    <>
      <div className={st.container}>
        <div className={st.header}>
          <h1 className={st.title}>Платим деньги за установку приложений</h1>
          <h2 className={st.subtitle}>Выводи на Qiwi или баланс мобильного!</h2>
        </div>
        <div className={st.videoWrapper}>
          <video
            className={st.video}
            src={video}
            muted
            autoPlay
            playsInline="playsinline"
            loop
          ></video>
        </div>
        <button
          className={st.btn}
          onClick={onClickDownload}
          id="buttonDownloadLanding"
        >
          <img className={st.btnImg} src={btn} />
        </button>
      </div>
      <Drawer isDrawerOpen={isDrawerOpen}>
        <TutorialiOS close={toggleDrawer} appURL={appURL} />
      </Drawer>
    </>
  )
}

export default VideoLanding
