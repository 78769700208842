import React from "react"
import platform from "platform"
import { mobileProvisionUrl } from "../../utils/siteSettings"
import st from "./TutorialiOS.module.css"

import logoAppbonus from "../../assets/images/TutorialStatic/logo-appbonus.png"
import iconAppbonus from "../../assets/images/TutorialStatic/icon-appbonus.png"
import iconClose from "../../assets/images/TutorialStatic/icon-close.svg"
import stepDownload from "../../assets/images/TutorialStatic/img-step-1.png"
import stepOld1 from "../../assets/images/TutorialStatic/tutorial-ios-old-1.jpg"
import stepOld2 from "../../assets/images/TutorialStatic/tutorial-ios-old-2.jpg"
import stepOld3 from "../../assets/images/TutorialStatic/tutorial-ios-old-3.jpg"
import stepOld4 from "../../assets/images/TutorialStatic/tutorial-ios-old-4.jpg"
import stepIos15_1 from "../../assets/images/TutorialStatic/tutorial-ios15-1.jpg"
import stepIos15_2 from "../../assets/images/TutorialStatic/tutorial-ios15-2.png"
import stepIos15_3 from "../../assets/images/TutorialStatic/tutorial-ios15-3.png"
import stepIos15_4 from "../../assets/images/TutorialStatic/tutorial-ios15-4.png"

const TutorialiOS = ({ close, appURL }) => {
  const currentPlatformVersion = parseInt(platform.version)

  const iosOldTpl = (
    <>
      <div className={st.block}>
        <p className={st.block__label}>
          Зайдите в&nbsp;
          <b>
            <a
              className={st.block__link}
              href={mobileProvisionUrl}
              id="linkSettingsIosOld"
            >
              настройки
            </a>
          </b>{" "}
          в раздел <b>"Основные"</b>
        </p>
        <div className={st.block__img}>
          <img className={st.block__imgItem} src={stepOld1} />
        </div>
      </div>
      <div className={st.block}>
        <p className={st.block__label}>
          Пролистайте вниз и перейдите в раздел <b>"Управление устройством"</b>
        </p>
        <div className={st.block__img}>
          <img className={st.block__imgItem} src={stepOld2} />
        </div>
      </div>
      <div className={st.block}>
        <p className={st.block__label}>
          Найдите пункт <b>"MOBAILDEVELOPMENT, ООО"</b>
        </p>
        <div className={st.block__img}>
          <img className={st.block__imgItem} src={stepOld3} />
        </div>
      </div>
      <div className={st.block}>
        <p className={st.block__label}>
          Нажмите<b>"Доверять"</b>
        </p>
        <div className={st.block__img}>
          <img className={st.block__imgItem} src={stepOld4} />
        </div>
      </div>
    </>
  )

  const ios15Tpl = (
    <>
      <div className={st.block}>
        <p className={st.block__label}>
          Зайдите в&nbsp;
          <b>
            <a
              className={st.block__link}
              href={mobileProvisionUrl}
              id="linkSettingsIos15"
            >
              "Настройки"
            </a>
          </b>{" "}
          в&nbsp;раздел <b>"Основные"</b>, пролистайте вниз и перейдите в раздел{" "}
          <b>"VPN и управление устройством"</b>
        </p>
        <div className={st.block__img}>
          <img className={st.block__imgItem} src={stepIos15_1} />
        </div>
      </div>
      <div className={st.block}>
        <p className={st.block__label}>
          Найдите пункт <b>"MOBAILDEVELOPMENT, ООО"</b>
        </p>
        <div className={st.block__img}>
          <img className={st.block__imgItem} src={stepIos15_2} />
        </div>
      </div>
      <div className={st.block}>
        <p className={st.block__label}>
          Нажмите <b>"Доверять"</b>
        </p>
        <div className={st.block__img}>
          <img className={st.block__imgItem} src={stepIos15_3} />
        </div>
      </div>
      <div className={st.block}>
        <p className={st.block__label}>
          Нажмите <b>"Доверять"</b> в открывшемся всплывающем окне
        </p>
        <div className={st.block__img}>
          <img className={st.block__imgItem} src={stepIos15_4} />
        </div>
      </div>
    </>
  )

  return (
    <div className={st.tutorialIos}>
      <div className={st.header}>
        <img width="165" height="36" alt="Appbonus logo" src={logoAppbonus} />
        <button className={st.close} onClick={close}>
          <img width="15" height="15" alt="Close icon" src={iconClose} />
        </button>
      </div>
      <h1 className={st.title}>
        Следуйте инструкции, чтобы начать получать деньги
      </h1>
      <a
        className={`${st.button} ${st.buttonRed} ${st.buttonHeader}`}
        href={appURL}
        id="buttonDownloadTutorial"
      >
        Загрузить
      </a>
      <div className={st.blocks}>
        <div className={st.block}>
          <p className={st.block__label}>
            Дождитесь полной загрузки приложения
          </p>
          <div className={st.block__img}>
            <img className={st.block__imgItem} src={stepDownload} />
          </div>
        </div>
        {currentPlatformVersion >= 15 ? ios15Tpl : iosOldTpl}
        <div className={st.block}>
          <a
            className={`${st.button} ${st.buttonRed}`}
            href={mobileProvisionUrl}
            id="buttonSettings"
          >
            Перейти в настройки
          </a>
        </div>
        <div className={st.block}>
          <p className={st.block__title}>
            Выполнив <span>инструкцию</span>,<br /> начните получать{" "}
            <span>деньги</span>,<br /> запустив приложение
          </p>
        </div>
        <div className={st.block}>
          <img className={st.block__logo} src={iconAppbonus} />
        </div>
        <div className={st.block}>
          <a
            className={`${st.button} ${st.buttonRed}`}
            href="appbonus://"
            id="buttonOpenAppbonus"
          >
            Запустить
          </a>
        </div>
      </div>
    </div>
  )
}

export default TutorialiOS
